import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";


function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="pt-24 pb-24 pb-24 bg-grey-400 relative">
        <div className="container mx-auto mb-24 flex flex-col items-center">
          <h2 className="my-4 text-3xl font-medium text-cyan text-center">
Nope. Don't think so.
          </h2>
        </div>
        </div>
    </Layout>
  );
}

export default NotFoundPage;
